import { settingService } from "@/services";

export const settingCtrl = {
  getMethodPay: async (orderType) => {
    const { data } = await settingService.getSetting();
    const { domicile_payments, local_payments, pickup_payments } = data.data;
    const paymentSetting = {
      LOCAL: local_payments,
      AT_HOME: domicile_payments,
      TO_PICK_UP: pickup_payments,
    };
    return paymentSetting[orderType];
  },

  getPriceDomicileService: async () => {
    const { data } = await settingService.getGlobalSetting();

    return data.data;
  },

  getDomicilePickupSetting: async () => {
    const { data } = await settingService.getDomicilePickupSetting();

    return data.data;
  },

  getGlobalSetting: async () => {
    const { data } = await settingService.getGlobalSetting();
    return data.data;
  },

  getReservationSetting: async () => {
    const { data } = await settingService.getReservationSetting();
    return data.data;
  },

  putLanguageGlobalSetting: async (newLang) => {
    let formData = new FormData();
    formData.append("language_web_menu", newLang);
    const { data } = await settingService.putGlobalSetting(formData);
    return data.data;
  },

  getPaymentCardUse: async () => {
    const { data } = await settingService.getPaymentCardUse();
    return data.data;
  },
};
